$(window).bind("load", function(){ 
  if (document.URL.match(/user_cards/)){
  //　　↑　URlに/crads/がある時のみ発火するように

    var payjp = Payjp(process.env.PAYJP_PUBLIC_KEY)
     //　↑公開鍵を登録し、起点となるオブジェクトを取得します

    var elements = payjp.elements();
　　　//　↑elementsを取得します。

    var numberElement = elements.create('cardNumber');
    var expiryElement = elements.create('cardExpiry');
    var cvcElement = elements.create('cardCvc');
　　// ↑　elementを生成します

    numberElement.mount('#number-form');
    expiryElement.mount('#expiry-form');
    cvcElement.mount('#cvc-form');
　　　// ↑　elementをDOM上に配置します

    var submit_btn = $("#info_submit");
    submit_btn.click(function (e) {
      e.preventDefault();
      payjp.createToken(numberElement).then(function (response) {
　　　　//  ↑↑　ここでトークンを作成　// createTokenの引数には任意のElement1つを渡します

        if (response.error) {  //  通信に失敗したとき
          alert(response.error.message)
          regist_card.prop('disabled', false)
        } else {
          alert("登録が完了しました");
          $("#card_token").append(
            `<input type="hidden" name="payjp_token" value=${response.id}>
            <input type="hidden" name="card_token" value=${response.card.id}>`
          );
          $('#card_form')[0].submit();
        　 //  ↑↑ここでtype='hidden'にしてsubmitにtokenを乗せています

          $("#card_number").removeAttr("name");
          $("#cvc-from").removeAttr("name");
          $("#exp_month").removeAttr("name");
        　$("#exp_year").removeAttr("name");
　　　　　　//  ↑↑ここでremoveAttrで記述を削除してます
        };
      });
    }); 
  }
});